@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
/* Reset Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Comic Neue", cursive;
  font-size: $FONT_SIZE_16; /* Make sure $BASE_FONT_SIZE is defined in "variables" */
  background: $WHITE;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $TEXT_PRIMARY; /* Make sure $PRIMARY_TEXT is defined in "variables" */
}

ul {
  list-style: none;
}
