// color variable
$PRIMARY: #0569e3;
$SECONDARY: #ff6229;
$WHITE: #ffffff;
$BLACK: #232323;

// ACTIONS
$SUCCESS: #3adb76;
$WARNING: #ffae00;
$ALERT: #cc4b37;

// GRAYSCALE
$GRAY_LIGHT: #f7f7f7;
$GRAY_DARK: #2d2d2d;

// TEXT COLORS
$TEXT_PRIMARY: #060721; // Main text color
$TEXT_SECONDARY: #ffffff; // Secondary text color

$BACKGROUND_COLOR: #0569e30b;
