// Define color variables in "variables" file

// Mixins
@mixin icon-style($width, $height) {
  width: $width;
  height: $height;
  margin-right: calc($MARGIN_SMALL/6);
}

@mixin box-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@mixin hover-style($color: $PRIMARY) {
  background-color: $color;
  color: $WHITE;
  border-radius: $BORDER_RADIUS;
  padding: calc($PADDING_SMALL/8) calc($PADDING_SMALL/4);
}

// screen size mixins

@mixin SM {
  @media screen and (min-width: #{$SCREEN_SM_MIN}) {
    @content;
  }
}

@mixin MD {
  @media screen and (min-width: #{$SCREEN_MD_MIN}) {
    @content;
  }
}

@mixin LG {
  @media screen and (min-width: #{$SCREEN_LG_MIN}) {
    @content;
  }
}

@mixin XL {
  @media screen and (min-width: #{$SCREEN_XL_MIN}) {
    @content;
  }
}

// animations

@mixin slideInLeft {
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
}

@mixin slideInRight {
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
}

@mixin slideUp {
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
}

@mixin slideDown {
  @keyframes slideDown {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }
}

// container style
@mixin container-styles {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin subheading-styles {
  font-weight: $FONT_WEIGHT_REGULAR;
  color: $GRAY_DARK;
  max-width: 100%;
  margin-bottom: $PADDING_SMALL;
}
