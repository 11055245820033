@import "../../../styles/main.scss";

// Variable for navbar padding
$navbar-padding: calc($PADDING_SMALL / 2);

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1000;
  transition: background-color 1.4s ease-in-out, box-shadow 1.4s ease-in-out;
  padding: $navbar-padding * 2 $navbar-padding * 4;

  // Styles for the brand link
  .brand-link {
    // Add styles for the brand link if needed
  }

  // Styles for the menu links
  .menu-links {
    .menu-list {
      display: flex;
      align-items: center;
      padding: calc($navbar-padding / 1.2) calc($navbar-padding * 2);
      border-radius: calc($BORDER_RADIUS * 6);
      background-color: $BACKGROUND_COLOR; // Optional: background color for the menu
      @include box-shadow; // Apply box-shadow mixin if needed
      .menu-item {
        text-transform: capitalize;

        &:not(:first-child) {
          margin-left: $MARGIN_SMALL;
        }

        .navlink-menu {
          color: $PRIMARY;
          font-weight: $FONT_WEIGHT_BOLD;
          transition: color 0.6s ease-in-out;

          &:hover {
            color: $SECONDARY;
          }

          &.active {
            color: $SECONDARY;
          }
        }
      }
    }
  }

  // Styles for the login and signup buttons
  .login-signup-container {
    .nav-button-list {
      display: flex;
      align-items: center;

      .button {
        &:not(:first-child) {
          margin-left: calc($MARGIN_SMALL / 2);
        }
      }
    }
  }
}
