// Input.scss
input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:focus {
    border: 1px solid #555;
  }
}
