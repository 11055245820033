// FONT FAMILY

// FONT WEIGHT
$FONT_WEIGHT_REGULAR: 400;
$FONT_WEIGHT_MEDIUM: 600;
$FONT_WEIGHT_BOLD: 700;

// FONT SIZE

$FONT_SIZE_36: 36px;
$FONT_SIZE_24: 24px;
$FONT_SIZE_20: 20px;
$FONT_SIZE_18: 18px;
$FONT_SIZE_16: 16px;
$FONT_SIZE_14: 14px;

// LINE HEIGHT

$LINE_HEIGHT_16: 1.6; // Adjust as needed
$LINE_HEIGHT_14: 1.4; // Adjust as needed
$LINE_HEIGHT_12: 1.2; // Adjust as needed