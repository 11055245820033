@import "../../styles/main.scss";

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
  background-color: $WHITE;
  & .login-card {
    @include box-shadow;
    padding: $PADDING_SMALL;
    border-radius: $BORDER_RADIUS;
  }
}
