@import "../../../styles/main.scss";

// Define common button variables
$button-min-width: 100%; // Full-width by default, adjust if needed

// Common button styles
.button-common {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: inherit;
  border-radius: $BORDER_RADIUS;
  border: none;
  outline: none;
  font-size: $FONT_SIZE_14;
  text-align: center;
  min-width: $button-min-width;
  transition: background-color 0.6s ease, border-color 0.3s ease;
  box-sizing: border-box;
  width: $MARGIN_X_LARGE;
  height: calc($MARGIN_MEDIUM/1.8);
  .icon {
    margin-right: 6px;
    width: 18px;
    height: 18px;
  }
}

// Primary button styles
.primary {
  @extend .button-common;
  color: $WHITE;
  background-color: $PRIMARY;

  // Hover state
  &:hover {
    color: $TEXT_PRIMARY;
    background-color: transparent;
    border: 1px solid $GRAY_DARK;
  }
}

// Secondary button styles
.secondary {
  @extend .button-common;
  color: $TEXT_PRIMARY;
  background-color: transparent;
  border: 1px solid $GRAY_DARK;

  // Hover state
  &:hover {
    color: $WHITE;
    background-color: $PRIMARY;
  }
}
