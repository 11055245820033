// SPACING
$SPACING_UNIT: 2rem;

// MARGIN
$MARGIN_NONE: 0;
$MARGIN_SMALL: $SPACING_UNIT;
$MARGIN_MEDIUM: $SPACING_UNIT * 2;
$MARGIN_LARGE: $SPACING_UNIT * 3;
$MARGIN_X_LARGE: $SPACING_UNIT * 4;

// PADDING
$PADDING_NONE: 0;
$PADDING_SMALL: $SPACING_UNIT;
$PADDING_MEDIUM: $SPACING_UNIT * 2;
$PADDING_LARGE: $SPACING_UNIT * 3;
$PADDING_X_LARGE: $SPACING_UNIT * 4;

// COMPONENT SPACING
$COMPONENT_SPACING: $SPACING_UNIT * 1.5;

// BORDER RADIUS
$BORDER_RADIUS: 0.4rem;

// RESPONSIVE SPACING (Media Query Breakpoints)
$SMALL_SCREEN: 600px;
$MEDIUM_SCREEN: 900px;
$LARGE_SCREEN: 1200px;

@media screen and (min-width: $SMALL_SCREEN) {
  $MARGIN_SMALL: $SPACING_UNIT * 2;
  $MARGIN_MEDIUM: $SPACING_UNIT * 3;
  $MARGIN_LARGE: $SPACING_UNIT * 4;
  $MARGIN_X_LARGE: $SPACING_UNIT * 5;

  $PADDING_SMALL: $SPACING_UNIT * 2;
  $PADDING_MEDIUM: $SPACING_UNIT * 3;
  $PADDING_LARGE: $SPACING_UNIT * 4;
  $PADDING_X_LARGE: $SPACING_UNIT * 5;

  $COMPONENT_SPACING: $SPACING_UNIT * 2;
}

@media screen and (min-width: $MEDIUM_SCREEN) {
  $MARGIN_MEDIUM: $SPACING_UNIT * 4;
  $PADDING_MEDIUM: $SPACING_UNIT * 4;
}

@media screen and (min-width: $LARGE_SCREEN) {
  $MARGIN_LARGE: $SPACING_UNIT * 5;
  $PADDING_LARGE: $SPACING_UNIT * 5;
}
